import React, { useState, useEffect } from 'react'
import { Header } from 'semantic-ui-react'
// Components
import { Seo, ProductCard, Loading, FilterSortMenu } from '../components'
// Styles
import { ProductsGrid } from '../styles/LandingPage.styles'
// Context
import { useStore } from '../context/StoreContext'
import { usePrismic } from '../context/PrismicContext'

const defaultFilterState = {
  option: '',
  filterBy: '',
  showFiltered: false,
}

const ProductsPage = () => {
  const [productList, setProductList] = useState([])
  const [filterByName, setFilterByName] = useState(null)
  const [{ option, filterBy, showFiltered }, setState] =
    useState(defaultFilterState)

  const {
    prismicData: { prismicProductsPage },
  } = usePrismic()
  const { storeData, isLoadingStore } = useStore()

  const { products, check_out_our_products, loading_products, by_type } =
    prismicProductsPage

  const allProducts = storeData['all-products']?.products ?? []
  const benefitOptions = storeData['benefit']?.subCategories ?? []
  const typeOptions = storeData['type']?.subCategories ?? []

  const handleShowProducts = (value, text) => {
    if (value === '') {
      setState(defaultFilterState)
      setProductList(allProducts)
    }
    setState({
      option: text,
      filterBy: value,
      showFiltered: true,
    })
  }

  useEffect(() => {
    if (!filterBy) return
    if (option === by_type[0].text) {
      setProductList(typeOptions[filterBy]?.products)
      setFilterByName(typeOptions[filterBy]?.name)
    } else {
      setProductList(benefitOptions[filterBy]?.products)
      setFilterByName(benefitOptions[filterBy]?.name)
    }
  }, [filterBy])

  if (isLoadingStore)
    return <Loading loading={true} message={loading_products[0].text} />

  return (
    <>
      <Seo title={products[0].text} />
      <FilterSortMenu
        types={typeOptions}
        benefits={benefitOptions}
        handleFilter={handleShowProducts}
      />
      <Header style={{ marginTop: '1em' }} textAlign="center" size="huge">
        {!filterBy
          ? check_out_our_products[0].text
          : `${option.split(' ')[1]}: ${filterByName}`}
      </Header>
      {showFiltered ? (
        <ProductsGrid>
          {productList?.map(product => (
            <ProductCard key={product.uid} {...product} />
          ))}
        </ProductsGrid>
      ) : (
        <ProductsGrid>
          {allProducts?.map(product => (
            <ProductCard key={product.uid} {...product} />
          ))}
        </ProductsGrid>
      )}
    </>
  )
}

export default ProductsPage
